import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import './SectionContact.css';

function SectionContact() {
  const form = useRef();
  const [isModalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageForm, setMessageForm] = useState('');
  const [messageError, setMessageError] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  // Walidacja
  const nameReg = /^(?!\s*$)[a-zA-ZÀ-ž\s'-]{2,}$/;
  const phoneReg = /^\+?[0-9\s\-]{7,15}$/;
  const emailReg =
    /^[a-z\d]+[\w\d.-]*@(?:[a-z\d]+[a-z\d-]+\.){1,5}[a-z]{2,6}$/i;
  const messageReg = /^(?!\s*$).{10,}$/;

  const validateName = (nameValue) => {
    if (isSubmitted && !nameReg.test(nameValue)) {
      setNameError('Nieprawidłowe imię i nazwisko');
    } else {
      setNameError('');
    }
  };

  const validateEmail = (emailValue) => {
    if (isSubmitted && !emailReg.test(emailValue)) {
      setEmailError('Nieprawidłowy adres e-mail');
    } else {
      setEmailError('');
    }
  };

  const validatePhone = (phoneValue) => {
    if (isSubmitted && !phoneReg.test(phoneValue)) {
      setPhoneError('Nieprawidłowy numer telefonu');
    } else {
      setPhoneError('');
    }
  };

  const validateMessageForm = (messageValue) => {
    if (isSubmitted && !messageReg.test(messageValue)) {
      setMessageError(
        'Wiadomość musi mieć co najmniej 10 znaków i nie może składać się wyłącznie z białych znaków'
      );
    } else {
      setMessageError('');
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    // Walidacja
    const isEmailValid = emailReg.test(email);
    const isNameValid = nameReg.test(name);
    const isPhoneValid = phoneReg.test(phone);
    const isMessageValid = messageReg.test(messageForm);

    // Ustawianie błędów
    if (!isEmailValid) setEmailError('Nieprawidłowy adres e-mail');
    if (!isNameValid) setNameError('Nieprawidłowe imię i nazwisko');
    if (!isPhoneValid) setPhoneError('Nieprawidłowy numer telefonu');
    if (!isMessageValid)
      setMessageError(
        'Wiadomość musi mieć co najmniej 10 znaków i nie może składać się wyłącznie z białych znaków'
      );

    // Jeśli jakiekolwiek pole jest nieprawidłowe, przerywamy wysyłanie formularza
    if (!isEmailValid || !isNameValid || !isPhoneValid || !isMessageValid) {
      return;
    }

    emailjs
      .sendForm('service_pskarf3', 'template_k69qt1s', form.current, {
        publicKey: 'zpQlZr40b0yIWBfcj',
      })
      .then(
        () => {
          setMessage(
            'Dziękuję za wysłanie wiadomości. Odezwę się do Ciebie w ciągu 24h.'
          );
          setModalOpen(true);
          resetForm();
        },
        (error) => {
          setMessage(`Coś poszło nie tak... ${error.text}`);
        }
      );
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const resetForm = () => {
    setMessage('');
    setEmail('');
    setEmailError('');
    setName('');
    setNameError('');
    setPhone('');
    setPhoneError('');
    setMessageForm('');
    setMessageError('');
    setIsSubmitted(false);
  };

  return (
    <section className='section-contact sections-container' id='kontakt'>
      <h2 className='headline-chapter'>Kontakt</h2>
      <div className='contact-info'>
        <div className='contact-data'>
          <p>
            Napisz do mnie bezpośrednio lub wypełnij formularz, aby umówić się
            na rozmowę.
          </p>
          <p>Odezwę się do Ciebie w ciągu 24h.</p>

          <a href='mailto:przystan.zmiany.zawodowej@gmail.com'>
            <div className='mail-container'>
              <img
                className='icon'
                src='/img/icon-mail.svg'
                alt='ikona maila'
              />
              przystan.zmiany.zawodowej@gmail.com
            </div>
          </a>
        </div>
        <div className='contact-form'>
          <form ref={form} onSubmit={sendEmail} onReset={resetForm}>
            <div className='form-group'>
              <label htmlFor='name'>Imię i Nazwisko</label>
              <input
                type='text'
                id='name'
                name='name'
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (isSubmitted) validateName(e.target.value);
                }}
                required
              />
              {nameError && <div className='error'>{nameError}</div>}
            </div>

            <div className='form-group'>
              <label htmlFor='email'>E-mail</label>
              <input
                type='email'
                id='email'
                name='email'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  if (isSubmitted) validateEmail(e.target.value);
                }}
                required
              />
              {emailError && <div className='error'>{emailError}</div>}
            </div>

            <div className='form-group'>
              <label htmlFor='phone'>Numer telefonu</label>
              <input
                type='tel'
                id='phone'
                name='phone'
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  if (isSubmitted) validatePhone(e.target.value);
                }}
                required
              />
              {phoneError && <div className='error'>{phoneError}</div>}
            </div>

            <div className='form-group'>
              <label htmlFor='message'>Treść wiadomości</label>
              <textarea
                id='message'
                name='message'
                rows='4'
                value={messageForm}
                onChange={(e) => {
                  setMessageForm(e.target.value);
                  if (isSubmitted) validateMessageForm(e.target.value);
                }}
                required
              ></textarea>
              {messageError && <div className='error'>{messageError}</div>}
            </div>

            <div className='permission'>
              <input
                type='checkbox'
                id='permission'
                name='permission'
                required
              />
              <i>
                Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z
                ustawą o ochronie danych osobowych w związku z realizacją
                zgłoszenia. Podanie danych jest dobrowolne, ale niezbędne do
                przetworzenia zapytania. Zostałem/am poinformowany/a, że
                przysługuje mi prawo dostępu do swoich danych, możliwości ich
                poprawiania, żądania zaprzestania ich przetwarzania.
                Administratorem danych osobowych jest Przystań Zmiany Zawodowej.
              </i>
            </div>
            <button type='submit'>Zarezerwuj bezpłatną konsultację</button>
            <button type='reset'>Wyczyść</button>
          </form>

          {isModalOpen && (
            <div className='modal-overlay'>
              <div className='modal'>
                <h2>Twoja wiadomość została wysłana!</h2>
                <p>Wkrótce się z Tobą skontaktuję.</p>
                <p>
                  <i>Agnieszka</i>
                </p>
                <button onClick={closeModal}>
                  <b>OK</b>
                </button>
              </div>
            </div>
          )}

          <div id='message-container' className='message-container'>
            {message}
          </div>
        </div>
        <img
          className='contact-img'
          alt='Zdjęcie przedstawiające Agnieszkę Baranowską na tle alejki z drzewami.'
          src='img/agnieszka-baranowska-przystan-zmiany-zawodowej.jpg'
        ></img>
      </div>
    </section>
  );
}

export default SectionContact;
